
import {computed, defineComponent} from "vue";
import KTSearch from "@/layout/header/partials/Search.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import {useStore} from "vuex";

export default defineComponent({
    name: "topbar",
    components: {
        //KTNotificationsMenu,
        //KTQuickLinksMenu,
        KTUserMenu,
        //KTSearch,
    },

    setup() {
        const store = useStore();

        const currentUser = computed(() => {
            return store.getters.currentUser;
        });

        return {
            currentUser,
        };
    }
});
