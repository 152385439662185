
import {defineComponent, computed, onMounted, watch, nextTick} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
import KTLoader from "@/components/Loader.vue";
import KTCreateApp from "@/components/modals/wizards/CreateAppModal.vue";
import KTToolbarButtons from "@/layout/extras/ToolbarButtons.vue";
import TariffsListDrawer from "@/layout/extras/TariffsListDrawer.vue";
import KTHelpDrawer from "@/layout/extras/HelpDrawer.vue";

import PhonesHelpDrawer from "@/layout/extras/PhonesHelpDrawer.vue";

import KTMessengerDrawer from "@/layout/extras/MessengerDrawer.vue";
import {Actions} from "@/store/enums/StoreEnums";
import {MenuComponent} from "@/assets/ts/components";
import {reinitializeComponents} from "@/core/plugins/keenthemes";
import {removeModalBackdrop} from "@/core/helpers/dom";
import {
    toolbarDisplay,
    loaderEnabled,
    contentWidthFluid,
    loaderLogo,
    asideEnabled,
    subheaderDisplay,
    themeLightLogo,
    themeDarkLogo,
} from "@/core/helpers/config";

export default defineComponent({
    name: "Layout",
    components: {
        KTAside,
        KTHeader,
        KTFooter,
        KTToolbar,
        KTScrollTop,
        KTMessengerDrawer,
        //KTToolbarButtons,
        KTUserMenu,
        TariffsListDrawer,
        KTHelpDrawer,

        PhonesHelpDrawer,

        KTLoader,
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        // show page loading
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

        // initialize html element classes
        HtmlClass.init();

        const pageTitle = computed(() => {
            return store.getters.pageTitle;
        });

        const breadcrumbs = computed(() => {
            return store.getters.pageBreadcrumbPath;
        });

        onMounted(() => {
            //check if current user is authenticated
            if (!store.getters.isUserAuthenticated) {
                router.push({name: "sign-in"});
            }

            nextTick(() => {
                reinitializeComponents();
            });
            // Simulate the delay page loading
            setTimeout(() => {
                // Remove page loader after some time
                store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
            }, 500);
        });

        watch(
            () => route.path,
            () => {
                MenuComponent.hideDropdowns(undefined);

                // check if current user is authenticated
                if (!store.getters.isUserAuthenticated) {
                    router.push({name: "sign-in"});
                }

                removeModalBackdrop();
                nextTick(() => {
                    reinitializeComponents();
                });
            }
        );

        return {
            toolbarDisplay,
            loaderEnabled,
            contentWidthFluid,
            loaderLogo,
            asideEnabled,
            subheaderDisplay,
            pageTitle,
            breadcrumbs,
            themeLightLogo,
            themeDarkLogo,
        };
    },
});
