
import {defineComponent, computed} from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTMenu from "@/layout/header/Menu.vue";

import {
    headerWidthFluid,
    headerFixed,
    headerFixedOnMobile,
    headerLeft,
    asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
    name: "KTHeader",
    props: {
        title: String,
    },
    components: {
        KTTopbar,
        KTMenu,
    },
    setup() {
        const isHeaderSticky = computed(() => {
            if (window.innerWidth > 768) {
                return headerFixed.value;
            } else {
                return headerFixedOnMobile.value;
            }
        });

        return {
            headerWidthFluid,
            headerLeft,
            asideDisplay,
            isHeaderSticky,
        };
    },
});
