
import {defineComponent, computed} from "vue";
import {useI18n} from "vue-i18n/index";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {Actions} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
    name: "kt-user-menu",
    components: {},
    setup() {
        const router = useRouter();
        const i18n = useI18n();
        const store = useStore();

        i18n.locale.value = localStorage.getItem("lang")
            ? (localStorage.getItem("lang") as string)
            : "en";

        const currentUser = computed(() => {
            return store.getters.currentUser;
        });

        const signOut = () => {
            store
                .dispatch(Actions.LOGOUT)
                .then(() => router.push({name: "sign-in"}));
        };

        const setLang = (lang) => {
            localStorage.setItem("lang", lang);
            i18n.locale.value = lang;
        };

        return {
            signOut,
            setLang,

            currentUser,
        };
    },
});
